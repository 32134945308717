import {connectRange} from "instantsearch.js/es/connectors";

const renderRangeInput = (renderOptions, isFirstRender) => {
    const {start, range, refine, widgetParams} = renderOptions;
    const [min, max] = start;

    if (isFirstRender) {
        const form = document.createElement("form");
        form.classList.add("row", "g-2", "align-items-center", "mb-3");

        form.addEventListener("submit", (event) => {
            event.preventDefault();

            const rawMinInputValue = parseFloat(event.target.elements.min.value);
            const rawMaxInputValue = parseFloat(event.target.elements.max.value);

            refine([
                Number.isFinite(rawMinInputValue) ? rawMinInputValue : undefined,
                Number.isFinite(rawMaxInputValue) ? rawMaxInputValue : undefined,
            ]);
        });

        widgetParams.container.appendChild(form);

        return;
    }

    widgetParams.container.querySelector("form").innerHTML = `
        <div class="col">
            <div class="input-group">
            <span class="input-group-text">
              AED
            </span>
                <input name="min" type="number" class="form-control" placeholder="${
        range.min
    }" value="${Number.isFinite(min) ? min : ""}">
            </div>
        </div>
        <div class="col-auto">—</div>
        <div class="col">
            <div class="input-group">
            <span class="input-group-text">
              AED
            </span>
                <input name="max" type="number" class="form-control" placeholder="${
        range.max
    }" value="${Number.isFinite(max) ? max : ""}">
            </div>
        </div>
        <input type="submit" class="btn btn-outline-primary w-100" style="width: 10px; " hidden/>
    `;
};

const customRangeInput = connectRange(renderRangeInput);

export const priceRangeInputWidget = customRangeInput({
    container: document.querySelector("#range-input-price"),
    attribute: "price",
});
