import {refinementList} from "instantsearch.js/es/widgets";

const refinementWidgets = [
    refinementList({
        container: `#refinement-list-owner_working_hours`,
        attribute: "owner_working_hours",
        cssClasses: {
            root: ["overflow-auto", "alg-filters"],
            item: "form-check",
            checkbox: "form-check-input",
            count: ["badge"],
        },
    })
];

for (const attribute of [
    "country",
    "sectors",
    "features",
    // "currency",
]) {
    refinementWidgets.push(
        refinementList({
            container: `#refinement-list-${attribute}`,
            attribute: attribute,
            cssClasses: {
                root: ["overflow-auto", "alg-filters"],
                item: "form-check",
                checkbox: "form-check-input",
                count: ["badge"],
            },
        })
    );
}

export {refinementWidgets};
