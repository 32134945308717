import { currentRefinements } from "instantsearch.js/es/widgets";

export const currentRefinementsWidget = currentRefinements({
  container: "#current-refinements",
  excludedAttributes: ["urgent_sale", "partial_sale"],
  cssClasses: {
    label: ["badge", "badge-outline", "text-blue", "custopm-badge"],
    category: ["badge", "badge-outline", "text-azure", "custopm-badge"],
    delete: [
      "btn",
      "btn-outline-dark",
      "custom-delete-button",
      "custopm-badge",
    ],
  },
});
