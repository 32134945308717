import {SearchBox} from "./widgets/searchBoxWidget";
import {searchPageHits} from "./widgets/searchPageHitsWidget";
import {currentRefinementsWidget} from "./widgets/currentRefinementsWidget";
import {priceRangeInputWidget} from "./widgets/priceRangeWidget";
import {refinementWidgets} from "./widgets/refinementListWidgets";
import {toggleRefinementWidgets} from "./widgets/toggleRefinementWidgets";
import {paginationWidget} from "./widgets/paginationWidget"
import {sortByPriceWidget} from "./widgets/sortByPrice";


export const widgets = [
    sortByPriceWidget,
    paginationWidget,
    searchPageHits,
    currentRefinementsWidget,
    priceRangeInputWidget,
    ...refinementWidgets,
    ...toggleRefinementWidgets,
];
