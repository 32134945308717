import {pagination} from 'instantsearch.js/es/widgets';


export const paginationWidget = pagination({
    container: '#pagination',
    showFirst: false,
    showLast: false,
    scrollTo: '.page-body',
    cssClasses: {
        // root: "card-body",
        list: "pagination",
        item: "page-item",
        selectedItem: "active",
        disabledItem: "disabled",
        link: "page-link"
    },
    templates: {
        first() {
            return '«'
        },
        previous() {
            return `
                <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                     stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                    <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                    <path d="M15 6l-6 6l6 6"></path>
                </svg> prev`
        }
        ,
        next() {
            return ` next
            <svg xmlns="http://www.w3.org/2000/svg" class="icon" width="24" height="24" viewBox="0 0 24 24"
                 stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round">
                <path stroke="none" d="M0 0h24v24H0z" fill="none"></path>
                <path d="M9 6l6 6l-6 6"></path>
            </svg>`
        },
        last() {
            return '»'
        }
    },
});
